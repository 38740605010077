import { z } from "zod"

export function enumWithDefaultSchema<const T extends readonly [string, ...string[]]>(
  enumValue: T,
  defaultValue: z.util.noUndefined<T[number]>
) {
  return z
    .string()
    .nullish()
    .transform((val) => (val === "" ? undefined : val))
    .pipe(z.enum<string, T>(enumValue).default(defaultValue))
}
