"use client"

import { type MutableRefObject, type ReactNode } from "react"
import clsx from "clsx"

import Slider, { useSliderState, type SliderProps } from "@unlikelystudio/react-slider"

import type { DistributiveOmit, Nullish } from "~/@types/generics"
import RichText from "~/components/ui/RichText"
import type { SbRichText } from "~/components/ui/RichText/_data/schema"
import { SliderArrowNavigation, type SliderArrowNavigationProps } from "~/components/ui/Slider/SliderArrowNavigation"
import { SliderBullets, type SliderBulletsProps } from "~/components/ui/Slider/SliderBullets"
import { Stack, type StackProps } from "~/components/abstracts/Stack"

import * as css from "./styles.css"

export type SliderComponentProps = {
  sliderContainerClassName?: string
  title?: {
    className?: string
    content?: Nullish<SbRichText | string>
  }
  customSliderRef?: MutableRefObject<HTMLDivElement | null>
  topContainerProps?:
    | ((isEnableDrag: boolean) => DistributiveOmit<StackProps, "direction">)
    | DistributiveOmit<StackProps, "direction">
  containerProps?: DistributiveOmit<StackProps, "direction">
  children: ReactNode[] | JSX.Element[] | undefined
  arrowNavigationProps?: DistributiveOmit<
    SliderArrowNavigationProps,
    "onNextClick" | "onPrevClick" | "prevDisabled" | "nextDisabled"
  >
  sliderProps?: SliderProps
  enableSliderBullets?: boolean
  sliderBulletProps?: SliderBulletsProps
  arrowNavigationPosition?: "top" | "center"
  sliderWrapperClassName?: string
}

function SliderComponent({
  title,
  containerProps,
  topContainerProps,
  customSliderRef,
  sliderContainerClassName,
  arrowNavigationPosition,
  arrowNavigationProps,
  sliderProps,
  sliderBulletProps,
  enableSliderBullets = false,
  sliderWrapperClassName,
  children,
}: SliderComponentProps) {
  const [{ prevSlide, nextSlide, enableDrag, maxSlideIndex, slideIndex }, setSliderState] = useSliderState()

  return (
    <Stack sprinklesCss={{ position: "relative" }} direction="column" {...(containerProps ?? {})}>
      <Stack {...(typeof topContainerProps === "function" ? topContainerProps(enableDrag) : topContainerProps ?? {})}>
        {title ? (
          typeof title.content === "string" ? (
            <h3 className={title.className}>{title.content}</h3>
          ) : (
            <RichText className={title.className} render={title.content} />
          )
        ) : null}
        {enableDrag && arrowNavigationPosition === "top" && (
          <SliderArrowNavigation
            onNextClick={nextSlide}
            onPrevClick={prevSlide}
            prevDisabled={slideIndex === 0}
            nextDisabled={slideIndex === maxSlideIndex}
            {...(arrowNavigationProps ?? {})}
          />
        )}
      </Stack>

      <div className={clsx(sliderWrapperClassName)}>
        <Slider
          containerClassName={sliderContainerClassName}
          customSliderRef={customSliderRef}
          setSliderState={setSliderState}
          snap
          autoBlockSlideIndexChange
          maxSlideIndexChange={1}
          {...(sliderProps ?? {})}
        >
          {children}
        </Slider>

        {enableDrag && arrowNavigationPosition === "center" && (
          <SliderArrowNavigation
            className={css.arrows}
            leftArrowClassName={css.leftArrow}
            rightArrowClassName={css.rightArrow}
            onNextClick={nextSlide}
            onPrevClick={prevSlide}
            prevDisabled={slideIndex === 0}
            nextDisabled={slideIndex === maxSlideIndex}
            {...(arrowNavigationProps ?? {})}
          />
        )}
      </div>

      {enableSliderBullets && enableDrag && (
        <SliderBullets currentIndex={slideIndex} totalItems={maxSlideIndex + 1} {...(sliderBulletProps ?? {})} />
      )}
    </Stack>
  )
}

export default SliderComponent
